//https://codepen.io/dariocorsi/pen/jqxERJ?editors=1100
//@author Dario Corsi
@function shadow-string($color1, $color2, $length) {
    $total-length: $length;
    $string: $color1 0px 0px;

    @while $length >0 {
        $mix-amount: 100 - (($length / $total-length) * 100);
        $mixed-color: mix($color1, $color2, $mix-amount);
        $string-addition: 0+px $length+px;
        $string: $mixed-color $string-addition,
        $string;
        $length: $length - 1;
    }

    @return $string
}



@mixin longshadow($color1, $color2, $length) {
    text-shadow: shadow-string($color1, $color2, $length);
}

.long-shadow {
    @include longshadow(hsl(219, 70%, 61%), hsl(246, 80%, 60%), 5);
}

.App {
    text-align: center;


}


.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
